import React from 'react';
import AddIcon from './AddIcon';
import SubtractIcon from './SubtractIcon';

const Icons = ({ input, isValid, index, alertText, arrayHelpers }) => {
    if (input.length === 1) {
        return <AddIcon index={index} isValid={isValid} alertText={alertText} arrayHelpers={arrayHelpers} />;
    } else if (index === input.length - 1) {
        return (
            <>
                <AddIcon index={index} isValid={isValid} alertText={alertText} arrayHelpers={arrayHelpers} />
                <SubtractIcon index={index} arrayHelpers={arrayHelpers} />
            </>
        );
    }
    return <SubtractIcon index={index} arrayHelpers={arrayHelpers} />;
};

export default Icons;
