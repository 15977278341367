import React from 'react';
import { useHistory } from 'react-router-dom';
import { usePortal } from 'context';

const ScheduleButton = () => {
    const history = useHistory();

    const {
        user: { username }
    } = usePortal();

    return (
        <section className="subheader-item">
            <button className="subheader-button" onClick={() => history.push(`/${username}/schedule`)}>
                <h2>{'Schedule'}</h2>
            </button>
        </section>
    );
};

export default ScheduleButton;
