import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { disableTyping } from 'utils';

/**
 * This is the old DatePicker time selection component to prevent bug with timepicker-ui on safari tablet/mobile
 */
const DatePickerHoursInput = ({
    names,
    values,
    disabled = { date: false, startTime: false, endTime: false },
    index,
    setFieldValue,
    setFieldTouched,
    isNew = true
}) => {
    const [isStartTime, setIsStartTime] = useState(false);
    const [isEndTime, setIsEndTime] = useState(false);

    const showStartTimeUnset = !isStartTime && isNew;
    const showEndTimeUnset = !isEndTime && isNew;

    return (
        <>
            <div className="time-container-row">
                <span className="placeholder">{'Start *'}</span>
                <DatePicker
                    selected={values.startTime}
                    onChange={value => {
                        setFieldValue(names.startTime, value);
                        setIsStartTime(true);
                    }}
                    onChangeRaw={disableTyping}
                    onBlur={() => {
                        setFieldTouched(`streams[${index}].startTime`, true, false);
                        setIsStartTime(true);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    minTime={new Date().setHours(0, 0, 0, 0)}
                    maxTime={new Date().setHours(22, 0, 0, 0)}
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    required
                    disabled={disabled.startTime}
                    className={`spacer${showStartTimeUnset ? ' field-unset' : ''}`}
                />
            </div>
            <div className="time-container-row">
                <span className="placeholder">{'Stop *'}</span>
                <DatePicker
                    selected={values.endTime}
                    onChange={value => {
                        setFieldValue(names.endTime, value);
                        setIsEndTime(true);
                    }}
                    onChangeRaw={disableTyping}
                    onBlur={() => {
                        setFieldTouched(`streams[${index}].endTime`, true, false);
                        setIsEndTime(true);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    // add 1 hour to min time
                    minTime={values.startTime.getTime() + 15 * 60000}
                    maxTime={new Date().setHours(23, 45, 0, 0)}
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    required
                    disabled={disabled.endTime}
                    className={`spacer${showEndTimeUnset ? ' field-unset' : ''}`}
                />
            </div>
        </>
    );
};

export default DatePickerHoursInput;
