import React, { useState } from 'react';
import Modal from 'react-modal';
import { usePortal } from 'context';
import Button from 'components/Button';
import Edit from './Edit';
import './edit.scss';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const editModalStyles = {
    content: {
        border: 'none',
        padding: 0,
        maxWidth: '1200px',
        margin: '0 auto',
        inset: 0,
        backgroundColor: '#16394e'
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
};

const EditCTA = ({ id }) => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const { setBodyScroll, setEventsTableScroll } = usePortal();

    const openModal = () => {
        setBodyScroll('hidden');
        setEventsTableScroll('hidden');
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setBodyScroll('');
        setEventsTableScroll('');
    };

    return (
        <>
            <Button text="Edit" onClick={openModal} className="link-button" />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={editModalStyles}
                contentLabel="Edit Modal" // TODO: modify aria-label
            >
                <Edit id={id} onClose={closeModal} />
            </Modal>
        </>
    );
};

export default EditCTA;
