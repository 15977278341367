import React, { useEffect } from 'react';
import { usePortal } from 'context';
import './streamsInfo.scss';

const StreamsInfo = () => {
    const {
        user: { username, viewReportUrl },
        streamCount,
        fetchStreamCount
    } = usePortal();

    useEffect(() => {
        fetchStreamCount(username);
    }, []);

    if (streamCount === -1 || streamCount === undefined) {
        return null;
    }

    return (
        <section className="subheader-item streams-info-container">
            <div className="streams-info">
                <h2>{streamCount}</h2>
                <p>{`Service${streamCount !== 1 ? 's' : ''} this month`}</p>
                <a href={viewReportUrl} target="_blank" rel="noopen noreferrer">
                    View Full Report
                </a>
            </div>
        </section>
    );
};

export default StreamsInfo;
