import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import SchedulerContainer from 'components/SchedulerContainer';
import Form from './Form';
import './schedule.scss';

const Schedule = () => (
    <SchedulerContainer>
        <section className="scheduler-form-container">
            <Form />
        </section>
    </SchedulerContainer>
);

export default Schedule;
