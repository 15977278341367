import axios from 'axios';
import config from '../config/default';
import { getClientAuthToken } from './auth';

/**
 * Get partner event by id
 * @param {String} organizationId - id of a partner
 * @param {String} id - id of the event
 * @return {AxiosPromise<any>}
 */
export const get = async (organizationId, id) => {
    try {
        const { data: event, status } = await axios({
            method: 'get',
            url: `${config.viewlogiesApiUrl}/portal/event/${organizationId}/${id}`,
            headers: {
                Authorization: getClientAuthToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        });
        //*** delete console */
        console.log(event);
        event.obituaryUrl = event.obituaryUrl || '';
        event.donationUrl = event.donationUrl || '';
        event.donationUrl2 = event.donationUrl2 || '';
        event.streams = event.streams.map(stream => {
            const { startTime, endTime } = stream;
            const date = new Date(startTime);
            date.setHours(0, 0, 0, 0);
            return {
                ...stream,
                date,
                startTime: new Date(startTime),
                endTime: new Date(endTime)
            };
        });
        return { data: event, status };
    } catch (e) {
        console.error(e);
        const { status, data } = e.response;
        return { status, message: data.message || e.message };
    }
};

/**
 * Get all partner's events
 * @param {String} organizationId - id of a partner
 * @param {Number} page - the page number
 * @param {String} start - start date ISO string
 * @return {AxiosPromise<any>}
 */
export const getAllInRange = async (organizationId, page, start) => {
    try {
        const { data, status } = await axios({
            method: 'get',
            url: `${config.viewlogiesApiUrl}/portal/event/${organizationId}/range/${page}/${start}`,
            headers: {
                Authorization: getClientAuthToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        });
        return { data, status };
    } catch (e) {
        console.error(e);
        const { status, data } = e.response;
        return { status, message: data.message || e.message };
    }
};

/**
 * Get count of streams for partner for current month
 * @param {String} organizationId - id of a partner
 * @return {Promise<{count, status: number}|{message: *, status: *}>}
 */
export const getStreamCount = async organizationId => {
    try {
        const {
            data: { count },
            status
        } = await axios({
            method: 'get',
            url: `${config.viewlogiesApiUrl}/portal/stream/${organizationId}/count`,
            headers: {
                Authorization: getClientAuthToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        });
        return { count, status };
    } catch (e) {
        console.error(e);
        const { status, data } = e.response;
        return { status, message: data.message || e.message };
    }
};
