import React from 'react';
import 'components/Input/MultiInput/multiInput.scss';

const AddIcon = ({ donationUrl, setValue }) => (
    <i
        className="fas fa-plus button-icon"
        onClick={() => {
            if (donationUrl) setValue(true);
            else alert('url missing, please enter a url to continue');
        }}
    />
);

export default AddIcon;
