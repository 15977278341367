export default {
    viewlogies: 'Viewlogies',
    viewlogiesAllCaps: 'VIEWLOGIES',
    phoneNumber: '(415) 763-8439',
    supportEmail: 'support@viewlogies.com',
    pronounced: 'pronounced: /v • eulogies/',
    copyright: 'Copyright © 2021 Viewlogies. All rights reserved.',
    errorIntro: 'There was an error',
    scheduleSuccess:
        'Thank you for submitting your next Viewlogies details! We will email the family the Viewlogies link and password that they may forward on to their loved ones.'
};
