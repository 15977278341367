import React from 'react';
import './schedulerHeader.scss';

const SchedulerHeader = ({ title, onClose }) => (
    <h1 className="scheduler-form-header">
        {title}
        <i className="fas fa-times close-icon" onClick={onClose} tabIndex={0} role="button" aria-label="close" />
    </h1>
);

export default SchedulerHeader;
