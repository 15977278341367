import crypto from 'crypto';
import validator from 'validator';
import config from 'config/default';

const { viewlogiesApiUrl } = config;

/**
 * Set a cookie
 */
export const setCookie = (name, value, expirationDays = 30) => {
    const d = new Date();
    d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = `${name}=${value};${expires};path=/`;
};

/**
 * // TODO: modernize
 * Get a cookie name and value
 * @param {String} name - name of the cookie
 */
export const getCookie = name => {
    const _name = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(_name) === 0) {
            return c.substring(_name.length, c.length);
        }
    }
    return '';
};

export const separateByCommasAndAnd = arr =>
    arr.length > 1 ? `${arr.slice(0, -1).join(', ')} and ${arr.slice(-1)}` : { 0: '', 1: arr[0] }[arr.length];

export const unique = arr => arr.filter((value, index, self) => self.indexOf(value) === index);

export const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
        return 'mobile';
    } else if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet';
    }
    return 'desktop';
};

export const isSafari = () =>
    /constructor/i.test(window.HTMLElement) ||
    (p => p.toString() === '[object SafariRemoteNotification]')(
        !window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)
    );

export const disableTyping = e => e.preventDefault();
