import axios from 'axios';
import { getClientAuthToken } from 'services/auth';
import config from 'config/default';

export const schedule = async (username, body) =>
    axios({
        method: 'post',
        url: `${config.viewlogiesApiUrl}/client/v2/schedule/${username}`,
        data: body,
        headers: {
            Authorization: getClientAuthToken(),
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    });

export const edit = async (username, eventId, body) =>
    axios({
        method: 'put',
        url: `${config.viewlogiesApiUrl}/portal/event/${username}/${eventId}`,
        data: body,
        headers: {
            Authorization: getClientAuthToken(),
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    });

export const stopStream = async (username, streamId) =>
    axios({
        method: 'put',
        url: `${config.viewlogiesApiUrl}/portal/stream/${username}/stop/${streamId}`,
        headers: {
            Authorization: getClientAuthToken(),
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    });

export const deleteStream = async (username, streamId) =>
    axios({
        method: 'delete',
        url: `${config.viewlogiesApiUrl}/portal/stream/${username}/${streamId}`,
        headers: {
            Authorization: getClientAuthToken(),
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    });

export const deleteEvent = async (username, eventId) =>
    axios({
        method: 'delete',
        url: `${config.viewlogiesApiUrl}/portal/event/${username}/${eventId}`,
        headers: {
            Authorization: getClientAuthToken(),
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    });

export const downloadQRPNG = async (username, eventId, firstName) =>
    axios({
        method: 'get',
        url: `${config.viewlogiesApiUrl}/portal/event/qr/${username}/${eventId}`,
        headers: {
            Authorization: getClientAuthToken()
        },
        responseType: 'blob'
    }).then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${firstName.toLowerCase()}-qr-sign.png`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });

export const downloadQRPDF = async (username, eventId, firstName) =>
    axios({
        method: 'get',
        url: `${config.viewlogiesApiUrl}/portal/event/pdf/${username}/${eventId}`,
        headers: {
            Authorization: getClientAuthToken()
        },
        responseType: 'blob'
    }).then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${firstName.toLowerCase()}-qr-sign.pdf`);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
