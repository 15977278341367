import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TimepickerUI } from 'timepicker-ui';
import moment from 'moment-timezone';

const getTimepickerUITimeStr = time => moment(time).format('hh:mm A');

const getTimepickerUITime = e => {
    let hours = parseInt(e.detail.hour);
    if (e.detail.type === 'AM' && hours === 12) {
        hours = 0;
    } else if (e.detail.type === 'PM' && hours !== 12) {
        hours += 12;
    }
    const minutes = parseInt(e.detail.minutes);
    return new Date(new Date().setHours(hours, minutes, 0, 0));
};

const TimepickerUIHoursInput = ({
    names,
    values,
    disabled = { date: false, startTime: false, endTime: false },
    index,
    setFieldValue,
    setFieldTouched,
    isNew = true
}) => {
    const [isStartTime, setIsStartTime] = useState(false);
    const [isEndTime, setIsEndTime] = useState(false);

    const showStartTimeUnset = !isStartTime && isNew;
    const showEndTimeUnset = !isEndTime && isNew;

    const [startTimeStr, setStartTimeStr] = useState(getTimepickerUITimeStr(values.startTime));
    const [endTimeStr, setEndTimeStr] = useState(getTimepickerUITimeStr(values.endTime));
    const startTimeRef = useRef(null);
    const endTimeRef = useRef(null);

    const startTimeHandler = useCallback(e => {
        setFieldValue(names.startTime, getTimepickerUITime(e));
        setIsStartTime(true);
    }, []);

    const endTimeHandler = useCallback(e => {
        setFieldValue(names.endTime, getTimepickerUITime(e));
        setIsEndTime(true);
    }, []);

    const tmOptions = {
        mobile: false,
        animation: false,
        okLabel: 'Confirm'
    };

    // start time
    useEffect(() => {
        const tmStart = startTimeRef.current;
        const tmStartPicker = new TimepickerUI(tmStart, {
            ...tmOptions,
            timeLabel: 'Start time'
        });
        tmStartPicker.create();
        tmStart.addEventListener('accept', startTimeHandler);
        return () => tmStart.removeEventListener('accept', startTimeHandler);
    }, [startTimeHandler]);

    // end time
    useEffect(() => {
        const tmEnd = endTimeRef.current;
        const tmEndPicker = new TimepickerUI(tmEnd, {
            ...tmOptions,
            timeLabel: 'End time'
        });
        tmEndPicker.create();
        tmEnd.addEventListener('accept', endTimeHandler);
        return () => tmEnd.removeEventListener('accept', endTimeHandler);
    }, [endTimeHandler]);

    return (
        <>
            <div className="time-container-row">
                <span className="placeholder">{'Start *'}</span>
                <div className="timepicker-ui" ref={startTimeRef}>
                    <input
                        type="text"
                        className={`timepicker-ui-input spacer${showStartTimeUnset ? ' field-unset' : ''}`}
                        defaultValue={startTimeStr}
                    />
                </div>
            </div>
            <div className="time-container-row">
                <span className="placeholder">{'Stop *'}</span>

                <div className="timepicker-ui" ref={endTimeRef}>
                    <input
                        type="text"
                        className={`timepicker-ui-input spacer${showEndTimeUnset ? ' field-unset' : ''}`}
                        defaultValue={endTimeStr}
                    />
                </div>
            </div>
        </>
    );
};

export default TimepickerUIHoursInput;
