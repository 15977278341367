import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik, Form, Field } from 'formik';
import { usePortal } from 'context';
import { separateByCommasAndAnd } from 'utils';
import MultiEmailInput from 'components/Input/MultiInput/Email';
import MultiPhoneInput from 'components/Input/MultiInput/Phone';
import 'components/Input/MultiInput/multiInput.scss';
import AddIcon from 'components/Button/AddIcon';
import SubtractIcon from 'components/Button/SubtractIcon';
import StreamsInput from 'components/Input/StreamsInput';
import { removeEvent } from 'features/Schedule/helpers';
import { getErrorNotifications, submitEdit } from 'features/Schedule/helpers';
import { testEmailsAndPhones } from 'features/Schedule/Form';
import m from 'features/Schedule/messages';
import { getEventTitle } from './Edit';

const EditForm = ({ event, title, setTitle, setUpdateMessage, closeModal }) => {
    const [isRemoving, setIsRemoving] = useState(false);
    const [secondUrlOpen, setSecondUrlOpen] = useState(event.donationUrl ? true : false);

    const {
        user,
        user: { username },
        setEvent,
        deleteEvent
    } = usePortal();

    const remove = () => {
        // remove with API
        setIsRemoving(true);
        if (!window.confirm(`Are you sure you want to delete ${title}?`)) {
            setIsRemoving(false);
            return;
        }
        removeEvent(username, event.id)
            .then(() => {
                // setUpdateMessage(`${title} successfully deleted.`); // TODO: get delete message to show successfully
                deleteEvent(event.id);
                closeModal();
                setIsRemoving(false);
            })
            .catch(err => {
                console.error(err);
                setIsRemoving(false);
            });
    };

    return (
        <Formik
            initialValues={{
                firstName: event.firstName,
                lastName: event.lastName,
                familyCoordinatorFirstName: event.familyCoordinatorFirstName,
                familyCoordinatorLastName: event.familyCoordinatorLastName,
                emails: event.familyCoordinatorEmails,
                phones: event.familyCoordinatorPhones,
                streams: event.streams.map(stream => {
                    stream.eventType = stream.name;
                    return stream;
                }), // eventType is the form value for stream.name
                obituaryUrl: event.obituaryUrl,
                donationUrl: event.donationUrl,
                donationUrl2: event.donationUrl2,
                isNotGuestList: !event.isGuestList,
                isNotRsvpForm: !event.isRsvpForm
            }}
            // validationSchema={Schema}
            onSubmit={async (values, { setFieldError, setSubmitting }) => {
                // reject invalid emails and phones on submit
                const isInvalidInput = testEmailsAndPhones(values, setFieldError);
                if (isInvalidInput) {
                    return;
                }

                // submit data to viewlogies API
                const { status, data, message } = await submitEdit(username, event, values);
                if (status === 'error') {
                    setFieldError('server', message || 'There was an error');
                } else {
                    const _title = getEventTitle(data);
                    const _updateMessage = `Thank you for updating ${_title}! We will notify the family of any Viewlogies details that have changed that they need to know about.`;
                    setTitle(_title);
                    setUpdateMessage(_updateMessage);
                    setEvent(data);
                }
                setSubmitting(false);
            }}
        >
            {({ values, errors, touched, isSubmitting, setFieldValue, setFieldError, setFieldTouched }) => {
                const errorNotifications = getErrorNotifications(values, errors, touched);
                const isSubmitDisabled = isSubmitting || !!Object.keys(errors).length || !!errorNotifications.length;
                const isRemoveDisabled = isSubmitting || isRemoving;
                return (
                    // TODO: show loading animation while isSubmitting === true
                    <Form>
                        {/* informant name */}
                        <span className="placeholder spacer">{m.informantName}</span>
                        <div className="field-container spacer">
                            <div className="name-container-row">
                                <Field
                                    name="familyCoordinatorFirstName"
                                    className={`text-input${
                                        errors.familyCoordinatorFirstName && touched.familyCoordinatorFirstName
                                            ? ' invalid'
                                            : ''
                                    }`}
                                />
                                <span className="secondary-placeholder">{m.firstName}</span>
                            </div>
                            <div className="name-container-row">
                                <Field
                                    name="familyCoordinatorLastName"
                                    className={`text-input${
                                        errors.familyCoordinatorLastName && touched.familyCoordinatorLastName
                                            ? ' invalid'
                                            : ''
                                    }`}
                                />
                                <span className="secondary-placeholder">{m.lastName}</span>
                            </div>
                        </div>

                        {/* informant emails */}
                        <div className="spacer">
                            <span className="placeholder spacer">{m.familyCoordinatorEmail}</span>
                            <MultiEmailInput emails={values.emails} touched={touched.emails || {}} />
                        </div>

                        {/* informant phones */}
                        <div className="spacer">
                            <span className="placeholder spacer">{m.familyCoordinatorPhone}</span>
                            <MultiPhoneInput phones={values.phones} setFieldValue={setFieldValue} />
                        </div>

                        {/* deceased name */}
                        <span className="placeholder end-spacer">{m.nameOfDeceased}</span>
                        <div className="field-container spacer">
                            <div className="name-container-row">
                                <Field
                                    name="firstName"
                                    className={`text-input${errors.firstName && touched.firstName ? ' invalid' : ''}`}
                                />
                                <span className="secondary-placeholder">{m.firstName}</span>
                            </div>
                            <div className="name-container-row">
                                <Field
                                    name="lastName"
                                    className={`text-input${errors.lastName && touched.lastName ? ' invalid' : ''}`}
                                />
                                <span className="secondary-placeholder">{m.lastName}</span>
                            </div>
                        </div>

                        {/* streams section */}
                        <div className="end-spacer">
                            <StreamsInput
                                user={user}
                                streams={values.streams}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                setFieldError={setFieldError}
                                eventId={event.id}
                                setUpdateMessage={setUpdateMessage}
                            />
                        </div>

                        {/* obituary url */}
                        <div className="spacer">
                            <span className="placeholder spacer">{m.obituaryUrl}</span>
                            <Field name="obituaryUrl" className={`text-input${errors.obituaryUrl ? ' invalid' : ''}`} />
                        </div>

                        {/* donation url */}
                        {/* donation url */}
                        <div className="spacer subsection-header">
                            <span className="placeholder spacer">{m.donationUrl}</span>
                            <div className="multi-input-container single-field">
                                <div className="multi-input-field">
                                    <Field
                                        name="donationUrl"
                                        className={`text-input${errors.donationUrl ? ' invalid' : ''}`}
                                    />
                                </div>
                                <AddIcon donationUrl={values.donationUrl} setValue={setSecondUrlOpen} />
                            </div>

                            {secondUrlOpen && (
                                <div className="multi-input-container single-field">
                                    <div className="multi-input-field">
                                        <Field
                                            name="donationUrl2"
                                            className={`text-input${errors.donationUrl2 ? ' invalid' : ''}`}
                                        />
                                    </div>
                                    <SubtractIcon setFieldValue={setFieldValue} setValue={setSecondUrlOpen} />
                                </div>
                            )}
                        </div>

                        <hr />

                        {/* use guest list checkbox */}
                        <div className="checkbox-container">
                            <div>
                                <Field type="checkbox" name="isNotGuestList" />
                            </div>
                            <div className="checkbox-description">{m.dontUseGuestList}</div>
                        </div>

                        {/* use rsvp list checkbox */}
                        <div className="checkbox-container">
                            <div>
                                <Field type="checkbox" name="isNotRsvpForm" />
                            </div>
                            <div className="checkbox-description">{m.dontUseRsvpForm}</div>
                        </div>

                        {/* error messages go right above submit button*/}
                        {errors.server ? (
                            <div className="error-notification">
                                <h4>{`${m.errorIntro}: ${errors.server}.`}</h4>
                                {/* TODO: look into issue with periods coming from certain errors and not from others */}
                            </div>
                        ) : (
                            errorNotifications.length > 0 && (
                                <div className="error-notification">
                                    <h4>{m.pleaseFix}</h4>
                                    <ul>
                                        {errorNotifications.map((errorMessage, index) => (
                                            <li key={index}>{errorMessage}</li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        )}

                        {/* submit button */}
                        <div className="submit-button-container spacer">
                            <button
                                type="submit"
                                disabled={isSubmitDisabled}
                                className={`submit-button${isSubmitDisabled ? ' disabled' : ''}`}
                            >
                                {m.submit}
                            </button>
                        </div>

                        {/* delete button */}
                        <div className="submit-button-container spacer">
                            <button
                                type="button"
                                disabled={isRemoveDisabled}
                                className={`submit-button${isRemoveDisabled ? ' disabled' : ''}`}
                                onClick={remove}
                            >
                                {`Delete ${title}`}
                            </button>
                        </div>

                        <span className="secondary-placeholder form-note">{m.requiredField}</span>
                        <div className="empty-spacer" />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default EditForm;
