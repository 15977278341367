import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { usePortal } from '../../context';
import Loader from '../Loader';

const Authentication = ({ children }) => {
    const { organizationId } = useParams();

    const {
        login,
        user: { isLoading, isLoggedIn, isLoginError }
    } = usePortal();

    useEffect(() => {
        if (isLoading && !isLoggedIn) {
            login(organizationId);
        }
    }, [isLoading, isLoggedIn]);

    // show loading bar until login complete
    if (isLoading) return <Loader />;
    // if not logged in or authentication error return user to login screen
    if (isLoginError || !isLoggedIn) {
        return <Redirect to={`/${organizationId}/login`} />;
    }
    // return child component if successfully authenticated
    return children;
};

export default Authentication;
