import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './schedulerContainer.scss';
import { useHistory } from 'react-router-dom';
import { usePortal } from '../../context';
import SchedulerFormContainer from '../SchedulerFormContainer';
import { DEFAULT_SUCCESS_MESSAGE } from '../../features/Success';

const SchedulerContainer = ({ children }) => {
    const history = useHistory();

    const {
        user: { name, username },
        setSuccessMessage
    } = usePortal();

    return (
        <div className="scheduler-container">
            <div className="scheduler-container-background" />
            <main className="main-content">
                <SchedulerFormContainer
                    title={`${name} Viewlogies Scheduler`}
                    onClose={() => {
                        setSuccessMessage(DEFAULT_SUCCESS_MESSAGE); // TODO: move to API?
                        history.push(`/${username}`);
                    }}
                >
                    {children}
                </SchedulerFormContainer>
            </main>
        </div>
    );
};

export default SchedulerContainer;
