import eventTypes from 'config/eventTypes.json';
import { schedule, edit, deleteStream, deleteEvent, stopStream as stop } from 'services/schedule';
import m from './messages';
import * as Yup from 'yup';

export function getUpdatedTimes(date, state) {
    const { eventStartTime, eventEndTime } = state;
    const startTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const endTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    startTime.setHours(eventStartTime.getHours(), eventStartTime.getMinutes(), 0, 0);
    endTime.setHours(eventEndTime.getHours(), eventEndTime.getMinutes(), 0, 0);
    return {
        eventDate: date,
        eventDateSet: true,
        eventStartTime: startTime,
        eventEndTime: endTime
    };
}

export const createNewStream = chapels => {
    const date = new Date();
    const startTime = new Date(date);
    const endTime = new Date(date);
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() - 1); // TODO: remove this hack once react-datepicker not choosing selected date doesn't call onChange error is figured out
    startTime.setHours(12, 0, 0, 0);
    endTime.setHours(13, 0, 0, 0);
    return {
        chapel: chapels[0].name,
        eventType: eventTypes[0],
        date,
        startTime,
        endTime
    };
};

export const submit = async (username, form) => {
    try {
        const {
            isUniquePins,
            isNotGuestList,
            isNotRsvpForm,
            isSlideshowUploadEmailEnabled,
            firstName,
            lastName,
            emails: familyCoordinatorEmails,
            phones: familyCoordinatorPhones,
            streams,
            ...rest
        } = form;
        const body = {
            isGlobalPin: !isUniquePins,
            isGuestList: !isNotGuestList,
            isRsvpForm: !isNotRsvpForm,
            isSlideshowUploadEmailEnabled,
            organizationId: username,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            ...rest,
            familyCoordinatorEmails,
            familyCoordinatorPhones,
            streams: streams.map(({ chapel, eventType, date, startTime, endTime }) => ({
                name: eventType,
                chapel,
                startTime: new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    startTime.getHours(),
                    startTime.getMinutes(),
                    0,
                    0
                ),
                endTime: new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    endTime.getHours(),
                    endTime.getMinutes(),
                    0,
                    0
                )
            }))
        };
        const {
            data: { failed: failedCameras }
        } = await schedule(username, body);
        return { status: 'success', failedCameras, message: m.success };
    } catch (e) {
        console.error(e);
        let message = e.message;
        if (e.response && e.response.data && e.response.data.message) {
            message = e.response.data.message;
        }
        return { status: 'error', message };
    }
};

export const submitEdit = async (username, event, values) => {
    try {
        const {
            isNotGuestList,
            isNotRsvpForm,
            familyCoordinatorFirstName,
            familyCoordinatorLastName,
            firstName,
            lastName,
            emails: familyCoordinatorEmails,
            phones: familyCoordinatorPhones,
            streams,
            ...rest
        } = values;
        const _values = {
            familyCoordinatorFirstName: familyCoordinatorFirstName.trim(),
            familyCoordinatorLastName: familyCoordinatorLastName.trim(),
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            ...rest,
            familyCoordinatorEmails: familyCoordinatorEmails.join(','),
            familyCoordinatorPhones: familyCoordinatorPhones.join(','),
            isGuestList: !isNotGuestList,
            isRsvpForm: !isNotRsvpForm,
            streams: streams.map(({ id, chapel, eventType, date, startTime, endTime }) => ({
                id,
                name: eventType,
                chapel,
                startTime: new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    startTime.getHours(),
                    startTime.getMinutes(),
                    0,
                    0
                ),
                endTime: new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    endTime.getHours(),
                    endTime.getMinutes(),
                    0,
                    0
                )
            }))
        };
        const body = {};
        if (_values.familyCoordinatorFirstName !== event.familyCoordinatorFirstName) {
            body.familyCoordinatorFirstName = _values.familyCoordinatorFirstName;
        }
        if (_values.familyCoordinatorLastName !== event.familyCoordinatorLastName) {
            body.familyCoordinatorLastName = _values.familyCoordinatorLastName;
        }
        if (_values.firstName !== event.firstName) {
            body.firstName = _values.firstName;
        }
        if (_values.lastName !== event.lastName) {
            body.lastName = _values.lastName;
        }
        if (_values.familyCoordinatorEmails !== event.familyCoordinatorEmails.join(',')) {
            body.familyCoordinatorEmails = _values.familyCoordinatorEmails;
        }
        if (_values.familyCoordinatorPhones !== event.familyCoordinatorPhones.join(',')) {
            body.familyCoordinatorPhones = _values.familyCoordinatorPhones;
        }
        if (_values.obituaryUrl !== event.obituaryUrl) {
            body.obituaryUrl = _values.obituaryUrl;
        }
        if (_values.donationUrl !== event.donationUrl) {
            body.donationUrl = _values.donationUrl;
        }
        if (_values.donationUrl2 !== event.donationUrl2) {
            body.donationUrl2 = _values.donationUrl2;
        }
        if (_values.isGuestList !== event.isGuestList) {
            body.isGuestList = _values.isGuestList;
        }
        if (_values.isRsvpForm !== event.isRsvpForm) {
            body.isRsvpForm = _values.isRsvpForm;
        }
        let bodyStreams = [];
        _values.streams.forEach(stream => {
            if (!stream.id) {
                bodyStreams.push(stream);
                return;
            }
            const eventStream = event.streams.find(s => s.id === stream.id);
            if (!eventStream) {
                return;
            }
            const bodyStream = {};
            if (stream.chapel !== eventStream.chapel) {
                bodyStream.chapel = stream.chapel;
            }
            if (stream.name !== eventStream.name) {
                bodyStream.name = stream.name;
            }
            if (stream.startTime.getTime() !== eventStream.startTime.getTime()) {
                bodyStream.startTime = stream.startTime;
            }
            if (stream.endTime.getTime() !== eventStream.endTime.getTime()) {
                bodyStream.endTime = stream.endTime;
            }
            if (Object.keys(bodyStream).length) {
                bodyStream.id = stream.id;
                bodyStreams.push(bodyStream);
            }
        });
        if (bodyStreams.length) {
            body.streams = bodyStreams;
        }
        if (!Object.keys(body).length) {
            return { status: 'error', message: 'No fields have been modified' };
        }
        const { data } = await edit(username, event.id, body);
        return { status: 'success', data };
    } catch (e) {
        console.error(e);
        let message = e.message;
        if (e.response && e.response.data && e.response.data.message) {
            message = e.response.data.message;
        }
        return { status: 'error', message };
    }
};

export const stopStream = async (username, streamId) => {
    try {
        await stop(username, streamId);
        return { status: 'success' };
    } catch (e) {
        console.error(e);
        let message = e.message;
        if (e.response && e.response.data && e.response.data.message) {
            message = e.response.data.message;
        }
        return { status: 'error', message };
    }
};

export const removeStream = async (username, streamId) => {
    try {
        await deleteStream(username, streamId);
        return { status: 'success' };
    } catch (e) {
        console.error(e);
        let message = e.message;
        if (e.response && e.response.data && e.response.data.message) {
            message = e.response.data.message;
        }
        return { status: 'error', message };
    }
};

export const removeEvent = async (username, eventId) => {
    try {
        await deleteEvent(username, eventId);
        return { status: 'success' };
    } catch (e) {
        console.error(e);
        let message = e.message;
        if (e.response && e.response.data && e.response.data.message) {
            message = e.response.data.message;
        }
        return { status: 'error', message };
    }
};

export const Schema = Yup.object().shape({
    arrangerEmail: Yup.string()
        .email()
        .required('Funeral arranger email is required.'),
    firstName: Yup.string().required('First name of deceased is required.'),
    lastName: Yup.string().required('Last name of deceased is required.'),
    emails: Yup.array()
        .of(Yup.string().email('Invalid email(s).'))
        // .required('At least one email is required.')
        .min(1, 'At least one email is required.'),
    phones: Yup.array(),
    streams: Yup.array()
        .of(
            Yup.object().shape({
                chapel: Yup.string().required('chapel is required.'),
                eventType: Yup.string().required('event type is required.'),
                date: Yup.date()
                    .min(new Date(), 'date must be in the future')
                    .required('date is required.'),
                startTime: Yup.date().required('start time is required.'),
                endTime: Yup.date().required('end time is required.')
            })
        )
        .required('At least one event is required.')
        .min(1, 'At least one event is required.'),
    // obituaryUrl: Yup.string().url('Obituary url must be a valid url.')
    obituaryUrl: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Obituary url must be a valid url.'
    ),
    donationUrl: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Donation url must be a valid url.'
    ),
    donationUrl2: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Donation url must be a valid url.'
    )
});

export const getErrorNotifications = (values, errors, touched) => {
    const errorNotifications = [];
    // firstName
    if (errors.firstName && touched.firstName) {
        errorNotifications.push(errors.firstName);
    }
    // lastName
    if (errors.lastName && touched.lastName) {
        errorNotifications.push(errors.lastName);
    }
    // emails
    if (touched.emails) {
        if (touched.emails[0] && (!values.emails || !values.emails[0])) {
            errorNotifications.push('At least one email is required.');
        }
        if (errors.emails && errors.emails.length && touched.emails) {
            errorNotifications.push(...errors.emails);
        }
    }
    // phones
    if (errors.phones) {
        errorNotifications.push(errors.phones);
    }
    // streams
    values.streams.forEach((s, i) => {
        const sTouched = touched.streams && touched.streams[i];
        const sErrors = errors.streams && errors.streams[i];
        if (sTouched && sErrors) {
            if (sTouched.date && sErrors.date) errorNotifications.push(`Event ${i + 1} ${sErrors.date}`);
            if (sTouched.startTime && sErrors.startTime) errorNotifications.push(`Event ${i + 1} ${sErrors.startTime}`);
            if (sTouched.endTime && sErrors.endTime) errorNotifications.push(`Event ${i + 1} ${sErrors.endTime}`);
        }
        /*
        // old solution, for now just check for errors
        if (sTouched && sErrors) {
            if (!sTouched.date) errorNotifications.push(`Event ${i + 1} date is not set`);
            else if (sErrors.date) errorNotifications.push(`Event ${i + 1} ${sErrors.date}`);
            if (!sTouched.startTime) errorNotifications.push(`Event ${i + 1} start time is not set`);
            // else if (sErrors.startTime) errorNotifications.push(`Event ${i + 1} ${sErrors.startTime}`);
            if (!sTouched.endTime) errorNotifications.push(`Event ${i + 1} end time is not set`);
            // else if (sErrors.endTime) errorNotifications.push(`Event ${i + 1} ${sErrors.endTime}`);
        } else if (sTouched) {
            if (!sTouched.date) errorNotifications.push(`Event ${i + 1} date is not set`);
            if (!sTouched.startTime) errorNotifications.push(`Event ${i + 1} start time is not set`);
            if (!sTouched.endTime) errorNotifications.push(`Event ${i + 1} end time is not set`);
        }*/
    });

    if (errors.obituaryUrl) {
        errorNotifications.push(errors.obituaryUrl);
    }

    if (errors.donationUrl) {
        errorNotifications.push(errors.donationUrl);
    }
    if (errors.donationUrl2) {
        errorNotifications.push(errors.donationUrl2);
    }

    return errorNotifications;
};
