import React from 'react';
import './multiInput.scss';

const AddIcon = ({ index, isValid, alertText, arrayHelpers }) => (
    <i
        className="fas fa-plus button-icon"
        onClick={() => {
            if (isValid) arrayHelpers.insert(index + 1, '');
            else alert(alertText);
        }}
    />
);

export default AddIcon;
