import React from 'react';
import { usePortal } from 'context';
import { separateByCommasAndAnd } from 'utils';
import SchedulerContainer from 'components/SchedulerContainer';
import './success.scss';

export const DEFAULT_SUCCESS_MESSAGE =
    'Thank you for submitting your next Viewlogies details! We will email the family the Viewlogies link and password that they may forward on to their loved ones.';

export const getTurnOnCameraMessage = failedCameras => {
    const names = separateByCommasAndAnd(failedCameras);
    return (
        `Please power on ${names}. ` +
        `We have received the request but will not be able to complete it until the camera${
            failedCameras.length === 1 ? ' is' : 's are'
        } powered on. ` +
        'We will be trying to complete the request every 20 minutes. Thank you.'
    );
};

const Success = () => {
    const { successMessage } = usePortal();

    return (
        <SchedulerContainer>
            <p className="scheduler-form-success">{successMessage}</p>
        </SchedulerContainer>
    );
};

export default Success;
