import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { usePortal } from 'context';
import { getErrorNotifications, createNewStream, submit } from './helpers';
import Select from 'components/Select';
import MultiEmailInput from 'components/Input/MultiInput/Email';
import MultiPhoneInput from 'components/Input/MultiInput/Phone';
import 'components/Input/MultiInput/multiInput.scss';
import AddIcon from 'components/Button/AddIcon';
import SubtractIcon from 'components/Button/SubtractIcon';
import StreamsInput from 'components/Input/StreamsInput';
import { getTurnOnCameraMessage } from 'features/Success';
import m from './messages';
// import { FALSE } from 'node-sass';

export const testEmailsAndPhones = ({ emails, phones }, setFieldError) => {
    let isInputError = false;
    if (!emails[0]) {
        return true;
    } else if (emails.find(email => !validator.isEmail(email))) {
        setFieldError('emails', ['Invalid informant email(s).']);
        isInputError = true;
    }

    // reject if invalid phone numbers
    if (phones.find(phone => !isPossiblePhoneNumber(phone) && phone !== '')) {
        setFieldError('phones', ['Invalid informant cell phone(s).']);
        isInputError = true;
    }
    return isInputError;
};

const SchedulerForm = () => {
    const history = useHistory();

    const {
        user,
        user: { username, chapels, funeralArrangers },
        setSuccessMessage
    } = usePortal();

    const [secondUrlOpen, setSecondUrlOpen] = useState(false);

    return (
        <Formik
            initialValues={{
                arrangerEmail: funeralArrangers[0],
                firstName: '',
                lastName: '',
                familyCoordinatorFirstName: '',
                familyCoordinatorLastName: '',
                emails: [''],
                phones: [''],
                streams: [createNewStream(chapels)],
                obituaryUrl: '',
                donationUrl: '',
                donationUrl2: '',
                isUniquePins: false,
                isSlideshowUploadEmailEnabled: false,
                isNotGuestList: false,
                isNotRsvpForm: false
            }}
            // validationSchema={Schema}
            onSubmit={async (values, { setFieldError, setSubmitting }) => {
                // reject invalid emails and phones on submit
                const isInvalidInput = testEmailsAndPhones(values, setFieldError);
                if (isInvalidInput) {
                    return;
                }

                // submit data to viewlogies API
                const { status, failedCameras, message } = await submit(username, values);
                setFieldError('server', status === 'error' ? message : undefined);
                if (status !== 'error') {
                    history.push(`/${username}/schedule/success`);
                }
                setSubmitting(false);
            }}
        >
            {({ values, errors, touched, isSubmitting, setFieldValue, setFieldError, setFieldTouched }) => {
                const errorNotifications = getErrorNotifications(values, errors, touched);
                const isSubmitDisabled =
                    isSubmitting ||
                    !Object.keys(touched).length ||
                    !!Object.keys(errors).length ||
                    !!errorNotifications.length;
                return (
                    // TODO: show loading animation while isSubmitting === true
                    <Form>
                        <div className={`field-container${funeralArrangers.length <= 1 ? ' hidden' : ''}`}>
                            {/* funeral arranger */}
                            <Select name="arrangerEmail" title={m.funeralArranger} values={funeralArrangers} />
                        </div>

                        {/* informant name */}
                        <span className="placeholder spacer">{m.informantName}</span>
                        <div className="field-container spacer">
                            <div className="name-container-row">
                                <Field
                                    name="familyCoordinatorFirstName"
                                    className={`text-input${
                                        errors.familyCoordinatorFirstName && touched.familyCoordinatorFirstName
                                            ? ' invalid'
                                            : ''
                                    }`}
                                />
                                <span className="secondary-placeholder">{m.firstName}</span>
                            </div>
                            <div className="name-container-row">
                                <Field
                                    name="familyCoordinatorLastName"
                                    className={`text-input${
                                        errors.familyCoordinatorLastName && touched.familyCoordinatorLastName
                                            ? ' invalid'
                                            : ''
                                    }`}
                                />
                                <span className="secondary-placeholder">{m.lastName}</span>
                            </div>
                        </div>

                        {/* informant emails */}
                        <div className="spacer">
                            <span className="placeholder spacer">{m.familyCoordinatorEmail}</span>
                            <MultiEmailInput emails={values.emails} touched={touched.emails || {}} />
                        </div>

                        {/* informant phones */}
                        <div className="spacer">
                            <span className="placeholder spacer">{m.familyCoordinatorPhone}</span>
                            <MultiPhoneInput phones={values.phones} setFieldValue={setFieldValue} />
                        </div>

                        {/* deceased name */}
                        <span className="placeholder end-spacer">{m.nameOfDeceased}</span>
                        <div className="field-container spacer">
                            <div className="name-container-row">
                                <Field
                                    name="firstName"
                                    className={`text-input${errors.firstName && touched.firstName ? ' invalid' : ''}`}
                                />
                                <span className="secondary-placeholder">{m.firstName}</span>
                            </div>
                            <div className="name-container-row">
                                <Field
                                    name="lastName"
                                    className={`text-input${errors.lastName && touched.lastName ? ' invalid' : ''}`}
                                />
                                <span className="secondary-placeholder">{m.lastName}</span>
                            </div>
                        </div>

                        {/* streams section */}
                        <div className="end-spacer">
                            <StreamsInput
                                user={user}
                                streams={values.streams}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                setFieldError={setFieldError}
                            />
                        </div>

                        {/* obituary url */}
                        <div className="spacer subsection-header">
                            <span className="placeholder spacer">{m.obituaryUrl}</span>
                            <Field name="obituaryUrl" className={`text-input${errors.obituaryUrl ? ' invalid' : ''}`} />
                        </div>

                        {/* donation url */}
                        <div className="spacer subsection-header">
                            <span className="placeholder spacer">{m.donationUrl}</span>
                            <div className="multi-input-container single-field">
                                <div className="multi-input-field">
                                    <Field
                                        name="donationUrl"
                                        className={`text-input${errors.donationUrl ? ' invalid' : ''}`}
                                    />
                                </div>
                                <AddIcon donationUrl={values.donationUrl} setValue={setSecondUrlOpen} />
                            </div>

                            {secondUrlOpen && (
                                <div className="multi-input-container single-field">
                                    <div className="multi-input-field">
                                        <Field
                                            name="donationUrl2"
                                            className={`text-input${errors.donationUrl2 ? ' invalid' : ''}`}
                                        />
                                    </div>
                                    <SubtractIcon setFieldValue={setFieldValue} setValue={setSecondUrlOpen} />
                                </div>
                            )}
                        </div>

                        <hr />

                        {/* use unique pin checkbox */}
                        {values.streams.length > 1 && (
                            <div className="checkbox-container spacer">
                                <div>
                                    <Field type="checkbox" name="isUniquePins" />
                                </div>
                                <div className="checkbox-description">{m.useUniquePins}</div>
                            </div>
                        )}

                        {/* use guest list checkbox */}
                        <div className="checkbox-container">
                            <div>
                                <Field type="checkbox" name="isSlideshowUploadEmailEnabled" />
                            </div>
                            <div className="checkbox-description">{m.enableSlideshowEmail}</div>
                        </div>

                        {/* use guest list checkbox */}
                        <div className="checkbox-container">
                            <div>
                                <Field type="checkbox" name="isNotGuestList" />
                            </div>
                            <div className="checkbox-description">{m.dontUseGuestList}</div>
                        </div>

                        {/* use rsvp list checkbox */}
                        <div className="checkbox-container">
                            <div>
                                <Field type="checkbox" name="isNotRsvpForm" />
                            </div>
                            <div className="checkbox-description">{m.dontUseRsvpForm}</div>
                        </div>

                        {/* error messages go right above submit button*/}
                        {errors.server ? (
                            <div className="error-notification">
                                <h4>{`${m.errorIntro}: ${errors.server}. ${m.tryAgain}`}</h4>
                                {/* TODO: look into issue with periods coming from certain errors and not from others */}
                            </div>
                        ) : (
                            errorNotifications.length > 0 && (
                                <div className="error-notification">
                                    <h4>{m.pleaseFix}</h4>
                                    <ul>
                                        {errorNotifications.map((errorMessage, index) => (
                                            <li key={index}>{errorMessage}</li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        )}

                        {/* submit button */}
                        <div className="submit-button-container spacer">
                            <button
                                type="submit"
                                disabled={isSubmitDisabled}
                                className={`submit-button${isSubmitDisabled ? ' disabled' : ''}`}
                            >
                                {m.submit}
                            </button>
                        </div>
                        <span className="secondary-placeholder form-note">{m.requiredField}</span>
                        <div className="empty-spacer" />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default SchedulerForm;
