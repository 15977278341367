import React from 'react';

export default {
    chapel: 'Chapel *',
    funeralArranger: 'Funeral Arranger *',
    firstName: 'First Name',
    lastName: 'Last Name',
    familyCoordinatorEmail: 'Informant Email(s) *',
    familyCoordinatorPhone: 'Informant Cell Phone(s)',
    nameOfDeceased: 'Name of Deceased *',
    informantName: 'Informant Name',
    event: 'Event',
    eventType: 'Event Type *',
    obituaryUrl: 'Obituary Page URL',
    donationUrl: 'Donation Page URL',
    donationUrl2: 'Second Donation Page URL',
    submit: 'Submit',
    errorFixInvalidFields: 'please fix the following invalid fields',
    requiredField: '* Indicates required field.',
    addEvent: 'Add event',
    removeEvent: 'Remove event',
    useUniquePins:
        'Use a unique PIN for each event. This option will send separate emails for each event and only used if you would like to restrict the viewing of one or more event.',
    dontUseGuestList: 'Do not include a guest list with this event.',
    dontUseRsvpForm: 'Do not include an RSVP form with this event',
    enableSlideshowEmail: (
        <>
            {'Allow family informant to upload '}
            <em>{'Slideshow Tribute Video.'}</em>
        </>
    ),
    pleaseFix: 'Please fix the following errors:',
    success:
        'Thank you for submitting your next Viewlogies details! We will email the family the Viewlogies link and password that they may forward on to their loved ones.',
    error: 'There was an error.',
    errorIntro: 'ERROR',
    tryAgain: 'Please try again.'
};
