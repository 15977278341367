import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { PortalProvider } from 'context';
import Authentication from 'components/Authentication';
import PortalLogin from 'features/Login';
import Dashboard from 'features/Dashboard';
import Schedule from 'features/Schedule';
import Success from 'features/Success';
import Redirect404 from 'features/Redirect404';
import sharedMessages from 'messages';
import './App.scss';

class App extends React.Component {
    render() {
        return (
            <div className="app-wrapper">
                <Helmet>
                    <title>{sharedMessages.viewlogiesAllCaps}</title>
                </Helmet>
                <PortalProvider>
                    <Router>
                        <Switch>
                            <Route path="/:organizationId/login" component={PortalLogin} />
                            <Route exact path="/:organizationId">
                                <Authentication>
                                    <Dashboard />
                                </Authentication>
                            </Route>
                            <Route path="/:organizationId/schedule/success">
                                <Authentication>
                                    <Success />
                                </Authentication>
                            </Route>
                            <Route path="/:organizationId/schedule">
                                <Authentication>
                                    <Schedule />
                                </Authentication>
                            </Route>
                            <Route path="/" component={Redirect404} />
                        </Switch>
                    </Router>
                </PortalProvider>
            </div>
        );
    }
}

export default App;
