import React from 'react';
import config from 'config/default';

const ScheduleButton = () => (
    <section className="subheader-item">
        <a
            className="subheader-button"
            href={`${config.viewlogiesClientUrl}/download`}
            target="_blank"
            rel="noopen noreferrer"
        >
            <h2>{'Download'}</h2>
        </a>
    </section>
);

export default ScheduleButton;
