import React from 'react';
import m from 'messages';
import './footer.scss';

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                <p className="emphasized">{m.pronounced}</p>
                <p className="copyright">{m.copyright}</p>
            </footer>
        );
    }
}

export default Footer;
