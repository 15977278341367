import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LoginContainer from './LoginContainer';
import m from './messages';
import './login.scss';
import { usePortal } from 'context';

// TODO: refactor this
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            email: '',
            showSubmit: false,
            showEmailInput: false,
            signInText: m.arrangerSignIn
        };

        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.toggleEmailInputChange = this.toggleEmailInputChange.bind(this);
        this.decideShowSubmit = this.decideShowSubmit.bind(this);
    }

    handlePasswordChange(event) {
        const { value: password } = event.target;
        const { setLoginError } = this.props;
        this.setState({ password }, () => {
            this.decideShowSubmit();
        });
        if (password === '') {
            setLoginError(false);
        }
    }

    handleEmailChange(event) {
        const { value: email } = event.target;
        const { setLoginError } = this.props;
        this.setState({ email }, () => {
            this.decideShowSubmit();
        });
        if (email === '') {
            setLoginError(false);
        }
    }

    toggleEmailInputChange() {
        const { showEmailInput } = this.state;
        this.setState({ showEmailInput: !showEmailInput }, () => {
            this.decideShowSubmit();
        });
        if (!showEmailInput) {
            this.setState({ signInText: m.defaultSignIn });
        } else {
            this.setState({ signInText: m.arrangerSignIn });
        }
    }

    decideShowSubmit() {
        const { showEmailInput, email, password } = this.state;
        if (showEmailInput) {
            if ((email === '') | (password === '')) {
                this.setState({ showSubmit: false });
            } else {
                this.setState({ showSubmit: true });
            }
        } else if (password === '') {
            this.setState({ showSubmit: false });
        } else {
            this.setState({ showSubmit: true });
        }
    }

    render() {
        const { onSubmit, loginError, helpMessage, inputType, emailInputType, passwordResetUrl, isIndividualArrangerSignIn } = this.props;
        const { password, email, showSubmit, showEmailInput, signInText } = this.state;
        const submit = ev => {
            onSubmit(email, password);
            ev.preventDefault();
        };
        return (
            <LoginContainer className="login-form">
                <form onSubmit={submit}>
                    {helpMessage && <p>{helpMessage}</p>}
                    {showEmailInput ? (
                        <input
                            type={emailInputType}
                            name="email"
                            placeholder={m.email}
                            onChange={this.handleEmailChange}
                            autoCorrect="off"
                            autoCapitalize="none"
                        />
                    ) : null}
                    <input
                        type={inputType}
                        name="password"
                        placeholder={m.password}
                        onChange={this.handlePasswordChange}
                        autoCorrect="off"
                        autoCapitalize="none"
                    />
                    {showSubmit ? (
                        <i
                            className="fas fa-chevron-right submit-icon"
                            onClick={submit}
                            tabIndex={0}
                            role="button"
                            aria-label="sign in"
                        />
                    ) : null}
                </form>
                <p className={`login-error${loginError ? '' : ' error-hidden'}`}>{m.loginError}</p>
                { isIndividualArrangerSignIn &&
                    <div className="alt-sign-in">
                        <span onClick={this.toggleEmailInputChange}>{signInText}</span>
                        <span>
                            <a href={passwordResetUrl} target="_blank">
                                {m.forgotPass}
                            </a>
                        </span>
                    </div>
                }
            </LoginContainer>
        );
    }
}

Login.defaultProps = {
    inputType: 'password',
    emailInputType: 'text',
    helpMessage: ''
};

Login.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    loginError: PropTypes.bool.isRequired,
    setLoginError: PropTypes.func.isRequired,
    helpMessage: PropTypes.string,
    inputType: PropTypes.string,
    emailInputType: PropTypes.string
};

export default Login;
