import React from 'react';
import validator from 'validator';
import { Field, FieldArray } from 'formik';
import Icons from './Icons';
import './multiInput.scss';

const MultiEmailInput = ({ emails, touched }) => (
    <FieldArray
        name="emails"
        render={arrayHelpers =>
            emails.map((email, index) => {
                const isValidEmail = validator.isEmail(email);
                const showInvalid = !(isValidEmail || email === '') || (email === '' && touched[0] === true);
                return (
                    <div className="multi-input-container single-field" key={index}>
                        <div className="multi-input-field">
                            <Field
                                name={`emails.${index}`}
                                className={`email-input input${showInvalid ? ' invalid' : ''}`}
                            />
                        </div>
                        <Icons
                            input={emails}
                            arrayHelpers={arrayHelpers}
                            index={index}
                            isValid={isValidEmail}
                            alertText="Email invalid, please enter a valid email to continue"
                        />
                    </div>
                );
            })
        }
    />
);

export default MultiEmailInput;
