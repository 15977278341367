import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { usePortal } from 'context';
import Loader from 'components/Loader';
import Login from 'components/Login';
import Header from '../../components/Header';
import { retrieveArrangerSignInStatus } from '../../services/auth.js';
import './login.scss';

const PortalLogin = () => {
    const { organizationId } = useParams();

    const {
        login,
        authenticate,
        passwordResetUrl,
        user: { name, isLoading, isLoggedIn, isLoginError }
    } = usePortal();

    const [isIndividualArrangerSignIn, setIsIndividualArrangerSignIn] = useState(false);

    // see if logged in on mount
    useEffect(() => {
        if (!isLoggedIn) {
            login(organizationId, false);
        }
    }, [isLoggedIn]);

    useEffect(() => { 
        const handleArrangerSignInCheck = async () => {
            setIsIndividualArrangerSignIn(await retrieveArrangerSignInStatus(organizationId));
        }
        
        handleArrangerSignInCheck();
    }, [organizationId]);

    // once user is logged in take them to the scheduler page
    if (isLoggedIn) {
        return <Redirect to={`/${organizationId}`} />;
    }

    if (isLoading) {
        return <Loader />;
    }

    // TODO: add helmet
    return (
        <div className="portal-login-container">
            <Header
                name={
                    <>
                        {'Partner Portal'}
                        <br />
                        {name}
                    </>
                }
            />
            <div className="portal-login">
                <Login
                    onSubmit={async (email, password) => {
                        await authenticate(organizationId, email, password);
                    }}
                    loginError={isLoginError}
                    passwordResetUrl={passwordResetUrl}
                    setLoginError={() => {}}
                    isIndividualArrangerSignIn={isIndividualArrangerSignIn}
                />
            </div>
        </div>
    );
};

export default PortalLogin;
