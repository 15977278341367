import axios from 'axios';
import { getCookie, setCookie } from 'utils';
import config from 'config/default';

const { viewlogiesApiUrl, cognitoUrl, cognitoClientId } = config;

/**
 * Store client auth token as cookie
 */
const setClientAuthToken = token => setCookie('scheduler_sid', token);

/**
 * Get client auth token from cookie
 */
export const getClientAuthToken = () => `Bearer ${getCookie('scheduler_sid')}`;

export function login(organizationId) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${viewlogiesApiUrl}/portal/auth/login/${organizationId}`,
            headers: {
                Authorization: getClientAuthToken()
            }
        })
            .then(({ data }) => resolve(data))
            .catch(e => {
                console.error(e);
                reject(e);
            });
    });
}

// export function login(username) {
//     return new Promise((resolve, reject) => {
//         axios({
//             method: 'get',
//             url: `${viewlogiesApiUrl}/portal/auth/login/${username}`,
//             headers: {
//                 Authorization: getClientAuthToken()
//             }
//         })
//             .then(({ data }) => resolve(data))
//             .catch(e => {
//                 console.error(e);
//                 reject(e);
//             });
//     });
// }

//Log in using AWS Cognito. Get and Set Client Token directly from that.
// export function authenticate(username, email, password) {
//     return new Promise((resolve, reject) => {
//         axios({
//             method: 'post',
//             url: `${cognitoUrl}`,
//             headers: {
//                 'Content-Type': 'application/x-amz-json-1.1',
//                 'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth'
//             },
//             data: {
//                 AuthFlow: 'USER_PASSWORD_AUTH',
//                 ClientId: cognitoClientId,
//                 AuthParameters: {
//                     USERNAME: email,
//                     PASSWORD: password
//                 }
//             }
//         })
//             .then(({ data }) => {
//                 const token = data.AuthenticationResult.AccessToken;
//                 console.log(data)
//                 // store response token as cookie for authentication
//                 setClientAuthToken(token);
//                 return resolve(data);
//             })
//             .catch(e => {
//                 console.error(e);
//                 return reject(e);
//             });
//     });
// }

export function authenticate(username, email, password) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: `${viewlogiesApiUrl}/portal/auth`,
            data: {
                organizationId: username,
                email,
                password
            }
        })
            .then(({ data }) => {
                const token = data.AuthenticationResult.AccessToken;
                setClientAuthToken(token);
                return resolve(data);
            })
            .catch(e => {
                console.error(e);
                return reject(e);
            });
    });
}

export function retrieveArrangerSignInStatus(organizationId) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${viewlogiesApiUrl}/portal/arrangerstatus/${organizationId}`,
            headers: {
                // Authorization: getClientAuthToken()
            }
        }).then(({ data }) => {
            resolve(data.individualArrangerSignIn)
        })
        .catch(e => {
            console.error(e);
            reject(e);
        });
    });
}
