const config = {
    local: {
        viewlogiesApiUrl: 'http://localhost:3000',
        viewlogiesClientUrl: 'http://localhost:3010',
        cognitoUrl: 'https://cognito-idp.us-west-1.amazonaws.com',
        signInUrl: 'https://viewlogies-schedulers.auth.us-west-1.amazoncognito.com',
        cognitoClientId: '5ta46aoslui6ol8oo4k0eigbns'
    },
    stage: {
        viewlogiesApiUrl: 'https://stage.viewlogies-dev.com',
        viewlogiesClientUrl: 'https://stage.viewlogies.net',
        cognitoUrl: 'https://cognito-idp.us-west-1.amazonaws.com',
        signInUrl: 'https://viewlogies-schedulers.auth.us-west-1.amazoncognito.com',
        cognitoClientId: '5ta46aoslui6ol8oo4k0eigbns'
    },
    prod: {
        viewlogiesApiUrl: 'https://viewlogies-dev.com',
        viewlogiesClientUrl: 'https://www.viewlogies.net',
        cognitoUrl: 'https://cognito-idp.us-west-1.amazonaws.com',
        signInUrl: 'https://viewlogies-schedulers.auth.us-west-1.amazoncognito.com',
        cognitoClientId: '5ta46aoslui6ol8oo4k0eigbns'
    }
};

export default config[process.env.REACT_APP_ENVIRONMENT];
