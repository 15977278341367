import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { disableTyping, getDeviceType, isSafari } from 'utils';
import TimepickerUIHoursInput from './TimepickerUIHoursInput';
import DatePickerHoursInput from './DatePickerHoursInput';
import './timePicker.scss';

const TimeInput = ({
    names,
    values,
    disabled = { date: false, startTime: false, endTime: false },
    index,
    setFieldValue,
    setFieldTouched,
    isNew = true
}) => {
    const [isDate, setIsDate] = useState(false);
    const [deviceType, setDeviceType] = useState(getDeviceType());

    const eventDateMinDate = new Date();
    const eventDateMaxDate = new Date().setDate(eventDateMinDate.getDate() + 120);
    const showDateUnset = !isDate && isNew;

    const dateRef = useRef(null);

    useEffect(() => {
        // for hiding keyboard on mobile devices
        // see https://stackoverflow.com/questions/48805053/hide-native-keyboard-on-mobile-when-using-react-date-picker
        if (deviceType === 'mobile' && dateRef.current !== null) {
            dateRef.current.input.readOnly = true;
        }
    }, []);

    return (
        <>
            <div className="field-container">
                <div className="time-container-row">
                    <span className="placeholder">{'Date *'}</span>
                    <DatePicker
                        ref={dateRef}
                        selected={values.date}
                        onChange={value => {
                            setFieldValue(names.date, value);
                            setIsDate(true);
                        }}
                        onChangeRaw={disableTyping}
                        onBlur={() => {
                            setFieldTouched(`streams[${index}].date`, true, false);
                            setIsDate(true);
                        }}
                        dateFormat="MM/dd/yyyy"
                        minDate={eventDateMinDate}
                        maxDate={eventDateMaxDate}
                        disabled={disabled.date}
                        className={`spacer${showDateUnset ? ' field-unset' : ''}`}
                        required
                    />
                </div>
                {/* The below solution is not ideal,
                 * TODO: figure out how to get timepicker-ui to work on safari mobile/tablet
                 */}
                {isSafari() && deviceType !== 'desktop' ? (
                    <DatePickerHoursInput
                        names={names}
                        values={values}
                        disabled={disabled}
                        index={index}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        isNew={isNew}
                    />
                ) : (
                    <TimepickerUIHoursInput
                        names={names}
                        values={values}
                        disabled={disabled}
                        index={index}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        isNew={isNew}
                    />
                )}
            </div>
        </>
    );
};

export default TimeInput;
