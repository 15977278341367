import React, { useEffect, useState } from 'react';
import { usePortal } from 'context';
import { separateByCommasAndAnd } from 'utils';
import { get as getEvent } from 'services/event';
import SchedulerFormContainer from 'components/SchedulerFormContainer';
import EditForm from './Form';
import './edit.scss';

// TODO: figure out a way to show a delete success message (create a new modal?)

export const getEventTitle = event =>
    `${event.firstName} ${event.lastName} ${separateByCommasAndAnd(event.streams.map(s => s.name))}`;

const EditContainer = ({ id, onClose }) => {
    const [event, setEvent] = useState(null);
    const [title, setTitle] = useState('');
    const [updateMessage, setUpdateMessage] = useState('');

    const {
        user: { username }
    } = usePortal();

    useEffect(() => {
        getEvent(username, id)
            .then(({ data }) => {
                setTitle(getEventTitle(data));
                //*** delete  console */
                console.log(data);
                setEvent(data);
            })
            .catch(e => console.error(e));
    }, [id]);

    const closeModal = () => {
        onClose();
        setUpdateMessage('');
    };

    let editForm = null;
    if (event) {
        editForm = (
            <EditForm
                event={event}
                title={title}
                setTitle={setTitle}
                setUpdateMessage={setUpdateMessage}
                closeModal={closeModal}
            />
        );
    }

    return (
        <div className="modal">
            <SchedulerFormContainer title={title ? `Editing ${title}` : ''} onClose={closeModal}>
                {updateMessage ? <p className="scheduler-form-success">{updateMessage}</p> : editForm}
            </SchedulerFormContainer>
        </div>
    );
};

export default EditContainer;
