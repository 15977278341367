import React from 'react';
import PropTypes from 'prop-types';
import m from 'messages';
import './header.scss';

const Header = ({ type, name }) => (
    <header className={`header header-${type}`}>
        <h1>
            <a href="https://viewlogies.com">{m.viewlogies}</a>
        </h1>
        {name && <h2 style={{ color: type }}>{name}</h2>}
    </header>
);

Header.propTypes = {
    type: PropTypes.oneOf(['black', 'white']),
    name: PropTypes.string
};

export default Header;
