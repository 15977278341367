import React from 'react';
import SchedulerHeader from 'components/SchedulerHeader';
import './schedulerFormContainer.scss';

const SchedulerFormContainer = ({ title, onClose, children }) => (
    <section className="scheduler-form">
        <SchedulerHeader title={title} onClose={onClose} />
        {children}
    </section>
);

export default SchedulerFormContainer;
