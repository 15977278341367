import React, { createContext, useContext, useEffect, useReducer } from 'react';
import moment from 'moment-timezone';
import { getAllInRange, getStreamCount } from 'services/event';
import { authenticate, login } from 'services/auth';
import { DEFAULT_SUCCESS_MESSAGE } from 'features/Success';
import { jwtDecode } from 'jwt-decode';

const PartnerContext = createContext();

const initialState = {
    user: {
        isLoading: true,
        isLoggedIn: false,
        isLoginError: false,
        organizationId: '',
        role: '',
        email: '',
        username: '',
        name: '',
        funeralArrangers: [],
        chapels: [],
        viewReportUrl: 'http://viewlogies.com/404',
        timezone: ''
    },
    events: null,
    eventsRange: {
        all: {},
        search: {}
    },
    eventsPage: 0,
    streamCount: -1,
    successMessage: DEFAULT_SUCCESS_MESSAGE,
    bodyScroll: '',
    eventsTableScroll: '',
    passwordResetUrl: 'https://us-west-12e1pwpxya.auth.us-west-1.amazoncognito.com/forgotPassword?client_id=3vg72fks0uavfpkincoqmumgs9&redirect_uri=https%3A%2F%2Fviewlogies.us%2F404&response_type=code&scope=email+openid+phone'
};

const updateEvent = (state, action) => {
    const event = action.payload;
    const { events } = state;
    const index = events.findIndex(ev => ev.id === event.id);
    events[index] = {
        ...events[index],
        ...event
    };
    return events;
};

const updateEventStream = (state, action) => {
    const { eventId, stream } = action.payload;
    const { events } = state;
    const index = events.findIndex(ev => ev.id === eventId);
    if (index !== -1) {
        const event = events[index];
        const { streams } = event;
        const streamIndex = streams.findIndex(s => s.id === stream.id);
        event.streams[streamIndex] = { ...streams[streamIndex], ...stream };
    }
    return events;
};

const deleteEvent = (state, action) => {
    const eventId = action.payload;
    const { events } = state;
    const index = events.findIndex(ev => ev.id === eventId);
    if (index !== -1) {
        events.splice(index, 1);
    }
    return events;
};

const deleteStream = (state, action) => {
    const { eventId, streamId } = action.payload;
    const { events } = state;
    const index = events.findIndex(ev => ev.id === eventId);
    if (index !== -1) {
        const event = events[index];
        const { streams } = event;
        const streamIndex = streams.findIndex(stream => stream.id === streamId);
        if (streamIndex !== -1) {
            events[index].streams.splice(streamIndex, 1);
        }
    }
    return events;
};

const reducer = (state, action) => {
    // update state through reducer actions
    switch (action.type) {
        case 'UPDATE_USER':
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            };
        case 'UPDATE_EVENTS':
            return {
                ...state,
                events: action.payload
            };
        case 'UPDATE_EVENT':
            return {
                ...state,
                events: updateEvent(state, action)
            };
        case 'UPDATE_EVENT_STREAM':
            return {
                ...state,
                events: updateEventStream(state, action)
            };
        case 'DELETE_EVENT':
            return {
                ...state,
                events: deleteEvent(state, action)
            };
        case 'DELETE_STREAM':
            return {
                ...state,
                events: deleteStream(state, action)
            };
        case 'UPDATE_EVENTS_PAGE':
            return {
                ...state,
                eventsPage: action.payload
            };
        case 'UPDATE_EVENTS_RANGE':
            return {
                ...state,
                eventsRange: action.payload
            };
        case 'UPDATE_CLOSEST_EVENT_INDEX':
            return {
                ...state,
                closestEventIndex: action.payload
            };
        case 'UPDATE_STREAM_COUNT':
            return {
                ...state,
                streamCount: action.payload
            };
        case 'UPDATE_SUCCESS_MESSAGE':
            return {
                ...state,
                successMessage: action.payload
            };
        case 'UPDATE_BODY_SCROLL':
            return {
                ...state,
                bodyScroll: action.payload
            };
        case 'UPDATE_EVENTS_TABLE_SCROLL':
            return {
                ...state,
                eventsTableScroll: action.payload
            };
        default:
            throw new Error();
    }
};

/**
 * Provider for EventsContext state
 */
const PortalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setUser = user => dispatch({ type: 'UPDATE_USER', payload: user });
    const setEvents = events => dispatch({ type: 'UPDATE_EVENTS', payload: events });
    const setEventsPage = page => dispatch({ type: 'UPDATE_EVENTS_PAGE', payload: page });
    const setEventsRange = range => dispatch({ type: 'UPDATE_EVENTS_RANGE', payload: range });
    const setClosestEventIndex = index => dispatch({ type: 'UPDATE_CLOSEST_EVENT_INDEX', payload: index });
    const setStreamCount = count => dispatch({ type: 'UPDATE_STREAM_COUNT', payload: count });
    const setSuccessMessage = message => dispatch({ type: 'UPDATE_SUCCESS_MESSAGE', payload: message });
    const setBodyScroll = scroll => dispatch({ type: 'UPDATE_BODY_SCROLL', payload: scroll });
    const setEventsTableScroll = scroll => dispatch({ type: 'UPDATE_EVENTS_TABLE_SCROLL', payload: scroll });
    const setEvent = event => dispatch({ type: 'UPDATE_EVENT', payload: event });
    const setEventStream = (eventId, stream) => dispatch({ type: 'UPDATE_EVENT_STREAM', payload: { eventId, stream } });
    const deleteEvent = eventId => dispatch({ type: 'DELETE_EVENT', payload: eventId });
    const deleteStream = ({ eventId, streamId }) => dispatch({ type: 'DELETE_STREAM', payload: { eventId, streamId } });

    return (
        <PartnerContext.Provider
            value={{
                ...state,
                setUser,
                setEvents,
                setEventsPage,
                setEventsRange,
                setClosestEventIndex,
                setStreamCount,
                setSuccessMessage,
                setBodyScroll,
                setEventsTableScroll,
                setEvent,
                setEventStream,
                deleteEvent,
                deleteStream
            }}
        >
            {children}
        </PartnerContext.Provider>
    );
};

/**
 * Call this method to access Context state in components that use Provider
 */
const usePortal = () => {
    const value = useContext(PartnerContext);
    const {
        user: { timezone },
        events: stateEvents,
        eventsPage,
        eventsRange,
        setUser,
        setEvents,
        setEventsPage,
        setEventsRange,
        setStreamCount,
        bodyScroll,
        eventsTableScroll
    } = value;

    useEffect(() => {
        document.body.style.overflow = eventsTableScroll;
    }, [bodyScroll]);

    useEffect(() => {
        const eventsTable = document.getElementsByTagName('table')[0];
        if (eventsTable) {
            eventsTable.style.overflow = eventsTableScroll;
        }
    }, [eventsTableScroll]);

    const _login = async (organizationId, setErrorOnFail = true) => {
        try {
            const data = await login(organizationId);
            const { user } = data;
            setUser({ isLoggedIn: true, isLoading: false, ...user });
        } catch (e) {
            const {
                response: {
                    status,
                    data: { name }
                }
            } = e;
            // redirect if organization id not found
            if (status === 404) {
                window.location.href = 'https://www.viewlogies.com/404';
            }
            // if 401 then login failed
            setUser({ name, isLoggedIn: false, isLoginError: !!setErrorOnFail, isLoading: false });
        }
    };

    const _authenticate = async (organizationId, email, password) => {
        try {
            const authData = await authenticate(organizationId, email, password);

            //***extract user data and sets user with new params
            const idToken = authData.AuthenticationResult.IdToken;
            const decodedToken = jwtDecode(idToken);
            const userRole = decodedToken['custom:role'];
            const username =decodedToken['email'];

            //*** set user with new variables */
            setUser({ loggedIn: true, isLoginError: false, role: userRole });
            // login after authenticating
            await _login(organizationId);
        } catch (e) {
            console.error(e);
            setUser({ isLoginError: true });
        }
    };

    const fetchEvents = async (organizationId, page = eventsPage) => {
        try {
            const start = moment(new Date())
                .tz(timezone)
                .format();
            const {
                data: { events, range }
            } = await getAllInRange(organizationId, page, start);
            if (!events || page === 0) {
                setEvents(events);
                setEventsRange({ all: range, search: range });
            } else {
                if (page < 0) {
                    setEvents([...events, ...stateEvents]);
                    setEventsRange({
                        all: { startTime: range.startTime, endTime: eventsRange.all.endTime },
                        search: range
                    });
                } else {
                    setEvents([...stateEvents, ...events]);
                    setEventsRange({
                        all: { startTime: eventsRange.startTime, endTime: range.endTime },
                        search: range
                    });
                }
            }
            setEventsPage(page);
            return events.length;
        } catch (e) {
            console.error('Unable to get partner events.');
            console.error(e);
            return -1;
        }
    };

    const fetchStreamCount = async organizationId => {
        try {
            const { count } = await getStreamCount(organizationId);
            if (count !== undefined) {
                setStreamCount(count);
            }
        } catch (e) {
            console.error('Unable to fetch stream count.');
            console.error(e);
        }
    };

    return { authenticate: _authenticate, login: _login, fetchEvents, fetchStreamCount, ...value };
};

export { usePortal, PortalProvider };
