import { Field } from 'formik';
import React from 'react';

const Select = ({ values, name, title, className, disabled = false }) => (
    <div className={`select-container spacer ${className}`}>
        <span className="placeholder">{title}</span>
        <Field as="select" name={name} disabled={disabled}>
            {values.map((name, index) => (
                <option value={name} key={index}>
                    {name}
                </option>
            ))}
        </Field>
    </div>
);

export default Select;
