import React from 'react';
import 'components/Input/MultiInput/multiInput.scss';

const SubtractIcon = ({ setFieldValue, setValue }) => (
    <i
        className="fas fa-minus button-icon"
        onClick={() => {
            setValue(false);
            setFieldValue('donationUrl2', '');
        }}
    />
);

export default SubtractIcon;
