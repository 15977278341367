import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { usePortal } from 'context';
import config from 'config/default';
import EditCTA from './Edit';
import Button from 'components/Button';
import { downloadQRPNG, downloadQRPDF } from 'services/schedule';
import './eventsTable.scss';

const timezones = {
    'America/Los_Angeles': 'Pacific Time',
    'America/Phoenix': 'Arizona Time',
    'America/Denver': 'Mountain Time',
    'America/Chicago': 'Central Time',
    'America/New_York': 'Eastern Time'
};

const SCROLL_OFFSET = 160;
const NO_EVENTS_FOUND_SCROLL_OFFSET = 122;
const SCROLL_DISABLED_TIMEOUT = 1000; // 1 second

const loadingOlderDefaultMessage = 'Loading older events...';
const getNoEventsFoundMessage = rangeStrs =>
    `No events found between ${rangeStrs.search.start} and ${rangeStrs.search.end}.`;

const pinToString = pin => (pin ? `${pin.substring(0, 3)}-${pin.substring(3, 6)}` : '');

const EventsTable = () => {
    const [showPagingOlder, setShowPagingOlder] = useState(false);
    const [isFetchingEvents, setIsFetchingEvents] = useState(false);
    const [loadingOlderMessage, setLoadingOlderMessage] = useState(loadingOlderDefaultMessage);
    const [isScrollDisabled, setIsScrollDisabled] = useState(false); // TODO
    const [scrollOffset, setScrollOffset] = useState(SCROLL_OFFSET);

    const {
        user: { username, timezone, funeralArrangers, role },
        fetchEvents,
        events,
        eventsPage,
        eventsRange,
        eventsTableScroll
    } = usePortal();

    // fetch events on render
    useEffect(() => {
        fetchEvents(username);
    }, []);

    useEffect(() => {
        const table = document.getElementsByTagName('table')[0];
        if (!table) {
            return;
        }
        table.scrollTo(0, SCROLL_OFFSET);
    }, [events]);

    if (!events) {
        return null;
    }

    const rangeStrs = {
        all: {
            start: moment(eventsRange.all.startTime)
                .tz(timezone)
                .format('M/D/YY'), // these dates are displayed in utc because that's how they're stored in db
            end: moment(eventsRange.all.endTime)
                .tz(timezone)
                .format('M/D/YY')
        },
        search: {
            start: moment(eventsRange.search.startTime)
                .tz(timezone)
                .format('M/D/YY'),
            end: moment(eventsRange.search.endTime)
                .tz(timezone)
                .format('M/D/YY')
        },
        tz: timezones[timezone]
    };

    const eventsRangeMessage = `Showing events for ${rangeStrs.all.start} to ${rangeStrs.all.end}.${
        rangeStrs.tz ? ` Event times are displayed in ${rangeStrs.tz}.` : ''
    }`;

    const getCircleColor = stream => {
        let circleColor = 'green';
        if (stream.ready === false && stream.isMobile === false) {
            circleColor = 'red';
        }
        return circleColor;
    };

    const enableEditAbility = event => {
        //if user role is admin or superadmin
        if (role === 'admin' || role === 'superadmin') {
            return true;
        }

        const lowercaseFuneralArrangers = funeralArrangers.map(email => email.toLowerCase());
        const arrangerEmail = event.arrangerEmail.toLowerCase();
        if (lowercaseFuneralArrangers.includes(arrangerEmail)) {
            return true;
        }
        return false;
    };

    const fetchPastEvents = table => {
        setIsFetchingEvents(true);
        fetchEvents(username, eventsPage - 1).then(count => {
            if (count === 0) {
                if (events.length === 0) {
                    setLoadingOlderMessage(loadingOlderDefaultMessage);
                    table.scrollTo(0, SCROLL_OFFSET);
                    setScrollOffset(SCROLL_OFFSET);
                    setShowPagingOlder(false);
                } else {
                    setLoadingOlderMessage(getNoEventsFoundMessage(rangeStrs));
                    table.scrollTo(0, NO_EVENTS_FOUND_SCROLL_OFFSET);
                    setScrollOffset(NO_EVENTS_FOUND_SCROLL_OFFSET);
                    setShowPagingOlder(true);
                    setTimeout(() => {
                        table.scrollTo(0, SCROLL_OFFSET);
                        setScrollOffset(SCROLL_OFFSET);
                        setShowPagingOlder(false);
                    }, 5000);
                }
            } else {
                setShowPagingOlder(false);
                table.scrollTo(0, SCROLL_OFFSET);
                setScrollOffset(SCROLL_OFFSET);
            }
            setIsFetchingEvents(false);
            setIsScrollDisabled(true);
            setTimeout(() => setIsScrollDisabled(false), SCROLL_DISABLED_TIMEOUT);
        });
    };

    const handleScroll = ({ target: table }) => {
        if (eventsTableScroll === 'hidden') {
            return;
        }
        if (isScrollDisabled) {
            // keep scroll at scroll x position set in state
            table.scrollTo(0, scrollOffset);
        } else if (table.scrollTop === 0) {
            setShowPagingOlder(true);
            if (!isFetchingEvents) {
                setIsFetchingEvents(true);
                fetchPastEvents(table);
            }
        } else if (showPagingOlder && table.scrollTop > 120) {
            setShowPagingOlder(false);
        }
    };

    return (
        <section className="events-table-container">
            <table className="events-table" onScroll={handleScroll}>
                <thead>
                    <tr>
                        <th>Ready</th>
                        <th>Name</th>
                        <th>Camera</th>
                        <th>Time</th>
                        <th>Edit</th>
                        <th>QR Sign</th>
                        <th>QR PNG</th>
                        <th>Slideshow</th>
                        <th>PIN</th>
                        <th>Link</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="tr-spacer">
                        {/* hacky little placeholder for keeping header row and body rows aligned, while allowing space to scroll up */}
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>
                    <p className={`loading-more loading-more-top${showPagingOlder ? '' : ' hidden'}`}>
                        {loadingOlderMessage}
                    </p>
                    {events && events.length === 0 && <p>{getNoEventsFoundMessage(rangeStrs)}</p>}
                    {events.map((event, i) => {
                        const pinQuery = event.pin ? `?pin=${event.pin}` : '';
                        const eventPageUrl = `${config.viewlogiesClientUrl}/${username}/${event.id}${pinQuery}`;
                        const eventPageLink = (
                            <a
                                className="event-item-value"
                                href={eventPageUrl}
                                target="_blank"
                                rel="noopen noreferrer"
                                onClick={e => e.stopPropagation()}
                            >
                                {'viewlogies.net'}
                            </a>
                        );
                        let uploadPageLink = null;
                        if (event.slideshowVimeoToken) {
                            uploadPageLink = (
                                <a
                                    className="event-item-value"
                                    href={`${config.viewlogiesClientUrl}/upload/${username}/${event.id}?token=${event.slideshowVimeoToken}`}
                                    target="_blank"
                                    rel="noopen noreferrer"
                                    onClick={e => e.stopPropagation()}
                                >
                                    {event.isSlideshowUploadComplete ? 'Replace' : 'Upload'}
                                </a>
                            );
                        }
                        const qrSignLink = (
                            <Button
                                text="Print"
                                onClick={() => downloadQRPDF(username, event.id, event.firstName)}
                                className="link-button"
                            />
                        );
                        const qrPNGLink = (
                            <Button
                                text="Download"
                                onClick={() => downloadQRPNG(username, event.id, event.firstName)}
                                className="link-button"
                            />
                        );
                        return (
                            <tr key={i}>
                                <td>
                                    {event.streams.map((stream, j) => (
                                        <div
                                            key={j}
                                            className={`ready-circle ready-circle-${getCircleColor(stream)}`}
                                        />
                                    ))}
                                </td>
                                <td>{`${event.firstName} ${event.lastName}`}</td>
                                <td>
                                    {event.streams.map((stream, j) => (
                                        <div key={j}>{stream.chapel}</div>
                                    ))}
                                </td>
                                {event.streams.length ? (
                                    <td>
                                        {event.streams.map((stream, j) => (
                                            <div key={j}>
                                                {moment(stream.startTime).format('M/D h:mmA')}-
                                                {moment(stream.endTime).format('h:mmA')}
                                            </div>
                                        ))}
                                    </td>
                                ) : (
                                    <td>{'No streams scheduled.'}</td>
                                )}
                                <td>{enableEditAbility(event) && <EditCTA id={event.id} />}</td>
                                <td>{qrSignLink}</td>
                                <td>{qrPNGLink}</td>
                                <td>{uploadPageLink}</td>
                                <td>
                                    {pinToString(event.pin) ||
                                        event.streams.map((stream, j) => <div key={j}>{pinToString(stream.pin)}</div>)}
                                </td>
                                <td>{eventPageLink}</td>
                            </tr>
                        );
                    })}
                    <tr className="tr-spacer tr-spacer-bottom" />
                </tbody>
            </table>
            <div className="events-dates">
                <p>{eventsRangeMessage}</p>
            </div>
        </section>
    );
};

export default EventsTable;
