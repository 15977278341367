import React from 'react';
import PropTypes from 'prop-types';
import m from 'messages';
import './contactUs.scss';

class ContactUs extends React.Component {
    render() {
        const { title, description, note } = this.props;

        return (
            <section className="contact-us">
                <hr />
                <div className="contact-us-content">
                    <h3>{title}</h3>
                    {description ? description : null}
                    <h2>{`${m.phoneNumber} / ${m.supportEmail}`}</h2>
                    {note ? <p>{note}</p> : null}
                </div>
                <hr />
            </section>
        );
    }
}

ContactUs.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.element,
    note: PropTypes.string
};

export default ContactUs;
