import React from 'react';
import 'settings.scss';

class Button extends React.Component {
    render() {
        const { text, children, ...buttonProps } = this.props;
        const className = `viewlogies-button${buttonProps.className ? ` ${buttonProps.className}` : ''}`;
        return (
            <button {...buttonProps} className={className}>
                <div className="button-content">{text}</div>
                {children}
            </button>
        );
    }
}

export default Button;
