export default {
    login: 'Login',
    password: 'Password',
    email: 'Email',
    loginError: 'Please try again.',
    help: 'Password is all lowercase including first letter, please verify your caps lock is off.',
    enterPIN: 'Please enter the service PIN',
    invalidPIN: 'Invalid PIN, please try again',
    helpPIN: 'Do not have the PIN?',
    contactInformant: 'Please contact the family informant to get the PIN',
    arrangerSignIn: 'Arranger sign-in',
    defaultSignIn: 'Admin sign-in',
    forgotPass: 'Forgot Password?'
};
