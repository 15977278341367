import React from 'react';
import { FieldArray } from 'formik';
import PhoneInput from 'react-phone-number-input/input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import Icons from './Icons';
import './multiInput.scss';

const MultiPhoneInput = ({ phones, setFieldValue }) => (
    <FieldArray
        name="phones"
        render={arrayHelpers =>
            phones.map((phoneNumber, index) => {
                const isValidPhoneNumber = isPossiblePhoneNumber(phoneNumber);
                const showInvalid = !(isValidPhoneNumber || phoneNumber === '');
                const name = `phones.${index}`;
                return (
                    <div className="multi-input-container single-field" key={index}>
                        <div className="multi-input-field">
                            <PhoneInput
                                name={name}
                                value={phoneNumber}
                                onChange={value => setFieldValue(name, value || '')}
                                className={`input${showInvalid ? ' invalid' : ''}`}
                                country="US"
                            />
                        </div>
                        <Icons
                            input={phones}
                            arrayHelpers={arrayHelpers}
                            index={index}
                            isValid={isValidPhoneNumber}
                            alertText="Phone number invalid, please enter a valid US phone number to continue"
                        />
                    </div>
                );
            })
        }
    />
);

export default MultiPhoneInput;
