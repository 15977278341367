import React from 'react';
import { usePortal } from 'context';
import Header from 'components/Header';
import ContactUs from 'components/ContactUs';
import Footer from 'components/Footer';
import ScheduleButton from './ScheduleButton';
import DownloadButton from './DownloadButton';
import StreamsInfo from './StreamsInfo';
// import MobileSolutionAd from './MobileSolutionAd';
import EventsTable from './EventsTable';
// import VideoIntro from './VideoIntro';
import './dashboard.scss';

const Dashboard = () => {
    const {
        user: { name }
    } = usePortal();

    return (
        <div className="dashboard-container">
            <main className="dashboard">
                <Header
                    type="white"
                    name={
                        <>
                            {'Partner Portal'}
                            <br />
                            {name}
                        </>
                    }
                />
                <div className="subheader-container">
                    <ScheduleButton />
                    <StreamsInfo />
                    <DownloadButton />
                    {/*<MobileSolutionAd />*/}
                </div>
                <EventsTable />
                {/*<div className="subfooter-container">*/}
                {/*    <VideoIntro title="Scheduling Mobile" url="https://vimeo.com/544693443" />*/}
                {/*    <VideoIntro title="Operating Mobile" url="https://vimeo.com/544697231" />*/}
                {/*</div>*/}
                <ContactUs title={'Need technical support?'} />
                <Footer />
            </main>
        </div>
    );
};

export default Dashboard;
