import React, { useState } from 'react';
import { FieldArray } from 'formik';
import { usePortal } from 'context';
import eventTypes from 'config/eventTypes.json';
import Select from 'components/Select';
import TimeInput from 'components/Input/TimeInput';
import { createNewStream, removeStream, stopStream } from 'features/Schedule/helpers';
import m from './messages';

const StreamsInput = ({
    user,
    streams,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    eventId = null,
    setUpdateMessage = null
}) => {
    const {
        user: { username, chapels },
        setEventStream,
        deleteStream
    } = usePortal();

    const [isRemoving, setIsRemoving] = useState(false);

    const add = arrayHelpers => {
        arrayHelpers.insert(streams.length, createNewStream(chapels));
    };

    const remove = (stream, index, arrayHelpers) => {
        if (!stream.id) {
            arrayHelpers.remove(index);
            return;
        }
        // remove with API
        setIsRemoving(true);
        if (!window.confirm(`Are you sure you want to delete ${stream.name}?`)) {
            setIsRemoving(false);
            return;
        }
        removeStream(username, stream.id)
            .then(() => {
                arrayHelpers.remove(index);
                deleteStream({ eventId, streamId: stream.id });
                setIsRemoving(false);
            })
            .catch(err => {
                console.error(err);
                setIsRemoving(false);
            });
    };

    const stop = stream => {
        if (!window.confirm(`Are you sure you want to stop ${stream.name} now?`)) {
            return;
        }
        stopStream(username, stream.id)
            .then(({ status, message }) => {
                stream.endTime = new Date();
                setEventStream(eventId, stream); // approximate end time
                if (status === 'success') {
                    setUpdateMessage(`${stream.name} successfully stopped.`);
                } else {
                    setFieldError('server', `Unable to stop ${stream.name}. ${message}`);
                }
            })
            .catch(err => {
                console.error(err);
                setFieldError('server', `Unable to stop ${stream.name}.`);
            });
    };

    return (
        <FieldArray
            name="streams"
            render={arrayHelpers => (
                <div className="streams-input-container multi-input-container">
                    {streams.map((stream, index) => {
                        const { eventType, date, startTime, endTime } = stream;
                        const now = new Date();
                        const disabled = {
                            camera: stream.id && now.getTime() > endTime.getTime(),
                            date: stream.id && now.getTime() > startTime.getTime(),
                            startTime: stream.id && now.getTime() > startTime.getTime(),
                            endTime: stream.id && now.getTime() > endTime.getTime(),
                            stop:
                                stream.id &&
                                !(now.getTime() > startTime.getTime() && now.getTime() < endTime.getTime()),
                            add: index !== streams.length - 1 || streams.length > 2,
                            remove: streams.length < 2 && !isRemoving
                        };
                        const camera = chapels.find(chapel => chapel.name === stream.chapel);
                        return (
                            <div className="spacer" key={index}>
                                {/* stream header */}
                                <span className="placeholder event-header">{eventType}</span>

                                <hr />

                                <div className="field-container">
                                    {/* stream chapel/camera */}
                                    <Select
                                        className={user.chapels.length <= 1 ? 'hidden' : ''}
                                        name={`streams[${index}].chapel`}
                                        title={m.camera}
                                        values={user.chapels
                                            .filter(({ isHidden }) => !isHidden)
                                            .map(({ name }) => name)}
                                        disabled={disabled.camera}
                                    />
                                    {/* stream event type */}
                                    <Select
                                        name={`streams[${index}].eventType`}
                                        title={m.eventType}
                                        values={eventTypes}
                                    />
                                </div>

                                {/* stream date, start, end */}
                                <TimeInput
                                    names={{
                                        date: `streams[${index}].date`,
                                        startTime: `streams[${index}].startTime`,
                                        endTime: `streams[${index}].endTime`
                                    }}
                                    values={{ date, startTime, endTime }}
                                    disabled={disabled}
                                    index={index}
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    isNew={!stream.id}
                                />

                                {/* stop button */}
                                {stream.id && (
                                    <div className="field-container">
                                        <button
                                            type="button"
                                            disabled={disabled.stop}
                                            onClick={() => stop(stream)}
                                            className={`submit-button${disabled.stop ? ' disabled' : ''}`}
                                        >
                                            {`Stop ${stream.name}`}
                                        </button>
                                    </div>
                                )}

                                {/* add/remove buttons */}
                                <div className="field-container">
                                    <button
                                        type="button"
                                        disabled={disabled.remove}
                                        onClick={() => remove(stream, index, arrayHelpers)}
                                        className={`submit-button${disabled.remove ? ' disabled' : ''}`}
                                    >
                                        {m.removeEvent}
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                    <hr />
                    {streams.length <= 3 && (
                        <button
                            type="button"
                            onClick={() => add(arrayHelpers)}
                            className="submit-button multi-input-button"
                            // className={`submit-button${disabled.add ? ' disabled' : ''}`}
                        >
                            {m.addEvent}
                        </button>
                    )}
                </div>
            )}
        />
    );
};

export default StreamsInput;
